import { gql } from "@apollo/client";

export const GET_PAGINATED_EBAY_ITEM_TAGS_QUERY = gql`
    query GetPaginatedEbayItemTags(
        $first: Int
        $last: Int
        $before: String
        $after: String
        $name_Contains: String
        $offset: Int
    ) {
        paginatedEbayItemTags(
            after: $after
            before: $before
            first: $first
            last: $last
            offset: $offset
            name_Contains: $name_Contains
        ) {
            pageInfo {
                endCursor
                hasPreviousPage
                hasNextPage
                startCursor
            }
            edges {
                cursor
                node {
                    id
                    name
                    color {
                        hex
                    }
                    itemCount
                    updatedAt
                    createdAt
                    archived
                }
            }
        }
    }
`;
