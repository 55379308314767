import React, { useState } from "react";
import { Link } from "react-router-dom";
import IconComponent from "@/components/icon/IconComponent";
import { NavigationItem, NavigationChild } from "@/types/globalTypes";
import { classNames } from "@/helpers/common/classNames";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

interface MobileNavItemProps {
    item: NavigationItem | NavigationChild;
    isActive: boolean;
    onClick: () => void;
    disabled?: boolean;
}

export const MobileNavItem: React.FC<MobileNavItemProps> = ({
    item,
    isActive,
    onClick,
    disabled = false,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const hasChildren =
        "children" in item && item.children && item.children.length > 0;

    const toggleDropdown = () => hasChildren && !disabled && setIsOpen(!isOpen);

    return (
        <React.Fragment>
            <li className="px-6">
                <Link
                    to={disabled ? "#" : item.href}
                    onClick={(e) => {
                        if (disabled) {
                            e.preventDefault();
                        } else if (hasChildren) {
                            e.preventDefault();
                            toggleDropdown();
                        } else {
                            onClick();
                        }
                    }}
                    className={classNames(
                        isActive
                            ? "bg-brand text-white"
                            : "text-gray-700 hover:bg-brand hover:text-white dark:text-gray-200 dark:hover:text-white",
                        disabled
                            ? "group flex cursor-not-allowed items-center justify-between rounded-md p-3 py-4 text-sm font-medium leading-6 opacity-50"
                            : "group flex w-full items-center justify-between rounded-md p-3 py-4 text-sm font-medium leading-6",
                    )}
                    aria-disabled={disabled}
                    tabIndex={disabled ? -1 : 0}
                >
                    <div className="flex items-center gap-x-3">
                        {"icon" in item && (
                            <IconComponent
                                iconName={item.icon}
                                className="h-6 w-6 shrink-0"
                                aria-hidden="true"
                            />
                        )}
                        {item.name}
                    </div>
                    {item.count != null && item.count > 0 && (
                        <span className="w-19 ml-2 inline-flex h-6 items-center justify-center rounded-full bg-red-500 p-1 text-[9px] font-medium text-white dark:bg-red-400 dark:text-white">
                            {item.count}
                        </span>
                    )}
                    {disabled && (
                        <span className="ml-2 inline-flex h-6 w-20 items-center justify-center rounded-full bg-gray-200 p-1 text-[10px] font-medium text-gray-800 dark:bg-gray-600 dark:text-gray-100">
                            Coming Soon
                        </span>
                    )}
                    {hasChildren && (
                        <span
                            className={`transition-transform duration-200 ${isOpen ? "rotate-180" : "rotate-0"}`}
                        >
                            <ChevronDownIcon className="h-5 w-5" />
                        </span>
                    )}
                </Link>
            </li>
            {isOpen && hasChildren && (
                <ul className="mt-1 space-y-1 pl-9">
                    {item.children.map((child, index) => (
                        <MobileNavItem
                            key={index}
                            item={child}
                            isActive={child.href === location.pathname}
                            onClick={onClick}
                            disabled={child.disabled}
                        />
                    ))}
                </ul>
            )}
        </React.Fragment>
    );
};
