import React, { useState, useEffect } from "react";
import LogoComponent from "@/components/logo/LogoComponent";
import { RESEND_VERIFICATION_EMAIL_MUTATION } from "@/graphql/mutations/authMutations";
import { useMutation } from "@apollo/client";
import { useAuth } from "@/hooks/auth/useAuth";

const AwaitingVerificationPage = () => {
    const [resendVerificationEmail] = useMutation(
        RESEND_VERIFICATION_EMAIL_MUTATION,
    );
    const [alertMessage, setAlertMessage] = useState<string | null>(null);
    const [alertType, setAlertType] = useState<"success" | "error">("success");

    const { user, logoutUser } = useAuth();

    useEffect(() => {
        if (user?.emailVerified) {
            window.location.href = "/dashboard";
        }
    }, [user]);

    const handleResendVerification = async (
        e: React.MouseEvent<HTMLAnchorElement>,
    ) => {
        e.preventDefault();
        try {
            const { data } = await resendVerificationEmail();
            if (data.resendVerificationEmail.success) {
                setAlertMessage(data.resendVerificationEmail.message);
                setAlertType("success");
            } else {
                setAlertMessage(data.resendVerificationEmail.message);
                setAlertType("error");
            }
        } catch (error) {
            console.error("Error resending verification email:", error);
            setAlertMessage("An error occurred. Please try again later.");
            setAlertType("error");
        }
    };

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <LogoComponent className="mx-auto h-20 w-auto" />
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight dark:text-white">
                    Awaiting Email Verification
                </h2>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                {alertMessage && (
                    <div
                        className={`mb-4 text-center text-sm ${alertType === "success" ? "text-green-600" : "text-red-600"}`}
                    >
                        {alertMessage}
                    </div>
                )}
                <p className="text-center text-sm dark:text-gray-400">
                    Thank you for signing up. A verification email has been sent
                    to your email address. Please check your inbox and click on
                    the verification link to activate your account.
                </p>
                <p className="mt-10 text-center text-sm dark:text-gray-400">
                    If you did not receive the email, please check your spam
                    folder or{" "}
                    <a
                        href="#"
                        onClick={handleResendVerification}
                        className="font-semibold leading-6 text-brand hover:text-brand"
                    >
                        click here
                    </a>{" "}
                    to resend the verification email.
                </p>
                <div className="mt-10">
                    <p className="text-center text-sm dark:text-gray-400">
                        Need help?{" "}
                        <a
                            href="https://fliplytics.co.uk/contact"
                            className="hover:text-brand-light font-semibold text-brand"
                        >
                            Contact our support team
                        </a>
                        .
                    </p>
                    <p className="mt-3 text-center text-sm dark:text-gray-400">
                        <a
                            href="#"
                            onClick={logoutUser}
                            className="hover:text-brand-light font-semibold text-brand"
                        >
                            Sign out
                        </a>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default AwaitingVerificationPage;
