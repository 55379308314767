import { gql } from "@apollo/client";

export const UPDATE_VAT_SETTINGS_MUTATION = gql`
    mutation UpdateVATSettingsMutation(
        $isVatRegistered: Boolean!
        $vatRate: Decimal
        $vatStartDate: Date!
        $vatType: String
        $canClaimVatOnCosts: Boolean
    ) {
        updateVatSettings(
            isVatRegistered: $isVatRegistered
            vatRate: $vatRate
            vatStartDate: $vatStartDate
            vatType: $vatType
            canClaimVatOnCosts: $canClaimVatOnCosts
        ) {
            settings {
                id
                createdAt
                isVatRegistered
                vatType
                vatRate
                vatStartDate
            }
        }
    }
`;

export const UPDATE_EBAY_ACCOUNT_SETTINGS_MUTATION = gql`
    mutation UpdateEbayAccountSettingsMutation(
        $matchPostagePriceWithCost: Boolean
        $defaultPackingCostPerItem: Decimal
        $defaultPostageCost: Decimal
    ) {
        updateEbayAccountSettings(
            matchPostagePriceWithCost: $matchPostagePriceWithCost
            defaultPackingCostPerItem: $defaultPackingCostPerItem
            defaultPostageCost: $defaultPostageCost
        ) {
            settings {
                id
                matchPostagePriceWithCost
                defaultPackingCostPerItem
                defaultPostageCost
            }
        }
    }
`;

export const INITIAL_UPDATE_EBAY_ACCOUNT_SETTINGS_MUTATION = gql`
    mutation InitialUpdateEbayAccountSettingsMutation(
        $canClaimVatOnCosts: Boolean
        $defaultPackingCostPerItem: Decimal
        $isVatRegistered: Boolean!
        $matchPostagePriceWithCost: Boolean
        $vatRate: Decimal
        $vatStartDate: Date!
        $vatType: String
    ) {
        initialUpdateEbayAccountSettings(
            isVatRegistered: $isVatRegistered
            vatStartDate: $vatStartDate
            vatType: $vatType
            vatRate: $vatRate
            matchPostagePriceWithCost: $matchPostagePriceWithCost
            defaultPackingCostPerItem: $defaultPackingCostPerItem
            canClaimVatOnCosts: $canClaimVatOnCosts
        ) {
            settings {
                id
                createdAt
                defaultPackingCostPerItem
                matchPostagePriceWithCost
                screenshotTextAlignment
                updatedAt
            }
            vatSettings {
                canClaimVatOnCost
                createdAt
                id
                isVatRegistered
                vatRate
                vatStartDate
                vatType
            }
        }
    }
`;

export const UPDATE_USER_MUTATION = gql`
    mutation UpdateUserMutation(
        $email: String
        $firstName: String
        $lastName: String
    ) {
        updateUser(email: $email, firstName: $firstName, lastName: $lastName) {
            message
            status
            success
            user {
                id
                firstName
                lastName
                email
            }
        }
    }
`;

export const RESET_USER_PASSWORD_MUTATION = gql`
    mutation ResetUserPasswordMutation(
        $newPassword: String!
        $oldPassword: String!
    ) {
        resetUserPassword(
            newPassword: $newPassword
            oldPassword: $oldPassword
        ) {
            success
            message
        }
    }
`;
