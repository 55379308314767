import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { SEARCH_QUERY } from "@/graphql/queries/ebay/search/searchQueries";
import { Link, useLocation } from "react-router-dom";
import CircularLoader from "@/components/loading/LoadingComponent";
import { decodeBase64Id } from "@/helpers/common/decodeBase64Id";

interface EbayItemType {
    id: string;
    itemName: string;
    listedAt: string;
    costOfGoodsPerItem: number;
    listedPrice: number;
}

interface EbaySaleType {
    id: string;
    ebay_item: EbayItemType;
    soldAt: string;
}

interface Column {
    key: keyof EbayItemType;
    label: string;
    render?: (value: any, item?: any) => React.ReactNode;
}

const columns: Column[] = [
    {
        key: "itemName",
        label: "Product",
        render: (value: string, item?: EbayItemType) => (
            <Link
                to={`/dashboard/products/${decodeBase64Id(item!.id)}`}
                className="text-blue-500 hover:underline"
            >
                {value}
            </Link>
        ),
    },
    {
        key: "listedAt",
        label: "Listed",
        render: (value: string) =>
            value ? new Date(value).toLocaleDateString() : "",
    },
    {
        key: "costOfGoodsPerItem",
        label: "Cost",
        render: (value: number) => (value != null ? `£${value}` : ""),
    },
    {
        key: "listedPrice",
        label: "Listed Price",
        render: (value: number) => (value != null ? `£${value}` : ""),
    },
];

const SearchResultsPage: React.FC = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const searchTerm = searchParams.get("query");

    const { data, loading, error } = useQuery(SEARCH_QUERY, {
        variables: { searchTerm },
        skip: !searchTerm,
    });

    const [selectedItems, setSelectedItems] = useState<string[]>([]);

    const handleCheckboxChange = (id: string) => {
        setSelectedItems((prevSelectedItems) =>
            prevSelectedItems.includes(id)
                ? prevSelectedItems.filter((itemId) => itemId !== id)
                : [...prevSelectedItems, id],
        );
    };

    if (loading) return <CircularLoader />;
    if (error) return <p>Error: {error.message}</p>;

    const searchResults: (EbayItemType | EbaySaleType)[] = data?.search || [];

    return (
        <div className="h-full dark:bg-gray-900">
            <div className="py-2 dark:bg-gray-900">
                <div className="px-2 sm:px-2 lg:px-1">
                    <div className="flow-root">
                        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                {/* Add overflow-x-auto for horizontal scroll */}
                                <div className="h-[calc(100vh-10rem)] overflow-x-auto overflow-y-hidden">
                                    <div className="h-[calc(100vh-10rem)] overflow-y-auto">
                                        <table className="min-w-[900px] table-auto divide-y divide-gray-400 dark:divide-gray-700 sm:min-w-full">
                                            <thead className="sticky top-0 z-10 bg-gray-200 dark:bg-gray-900">
                                                <tr>
                                                    <th
                                                        colSpan={
                                                            columns.length + 1
                                                        }
                                                        className="text-left"
                                                    >
                                                        <h3 className="text-base font-semibold leading-6 text-gray-900 dark:text-white">
                                                            Search Results
                                                        </h3>
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th
                                                        scope="col"
                                                        className="hidden w-10 py-3.5 pl-4 pr-3 text-left text-sm font-bold dark:text-white sm:pl-0"
                                                    >
                                                        <div className="relative inline-block h-4 w-4">
                                                            <label
                                                                htmlFor="selectAllCheckbox"
                                                                className="absolute inset-0 flex h-full w-full cursor-pointer items-center justify-center rounded-full border-2 border-gray-400 dark:border-gray-600"
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    onChange={(
                                                                        e,
                                                                    ) => {
                                                                        const isChecked =
                                                                            e
                                                                                .target
                                                                                .checked;
                                                                        if (
                                                                            isChecked
                                                                        ) {
                                                                            const allItemIds =
                                                                                searchResults.map(
                                                                                    (
                                                                                        item:
                                                                                            | EbayItemType
                                                                                            | EbaySaleType,
                                                                                    ) =>
                                                                                        decodeBase64Id(
                                                                                            item.id,
                                                                                        ),
                                                                                );
                                                                            setSelectedItems(
                                                                                allItemIds,
                                                                            );
                                                                        } else {
                                                                            setSelectedItems(
                                                                                [],
                                                                            );
                                                                        }
                                                                    }}
                                                                    checked={
                                                                        searchResults.length >
                                                                            0 &&
                                                                        selectedItems.length ===
                                                                            searchResults.length
                                                                    }
                                                                    className="peer absolute h-4 w-4 opacity-0"
                                                                    id="selectAllCheckbox"
                                                                />

                                                                <svg
                                                                    className="opacity-0 peer-checked:opacity-100"
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <g
                                                                        id="SVGRepo_bgCarrier"
                                                                        strokeWidth="0"
                                                                    ></g>
                                                                    <g
                                                                        id="SVGRepo_tracerCarrier"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    ></g>
                                                                    <g id="SVGRepo_iconCarrier">
                                                                        <path
                                                                            fillRule="evenodd"
                                                                            clipRule="evenodd"
                                                                            d="M1.5 12C1.5 6.20101 6.20101 1.5 12 1.5C17.799 1.5 22.5 6.20101 22.5 12C22.5 17.799 17.799 22.5 12 22.5C6.20101 22.5 1.5 17.799 1.5 12ZM15.7127 10.7197C16.0055 10.4268 16.0055 9.95192 15.7127 9.65903C15.4198 9.36614 14.9449 9.36614 14.652 9.65903L10.9397 13.3713L9.34869 11.7804C9.0558 11.4875 8.58092 11.4875 8.28803 11.7804C7.99514 12.0732 7.99514 12.5481 8.28803 12.841L10.4093 14.9623C10.7022 15.2552 11.1771 15.2552 11.47 14.9623L15.7127 10.7197Z"
                                                                            fill="#0064d2"
                                                                        ></path>
                                                                    </g>
                                                                </svg>
                                                            </label>
                                                        </div>
                                                    </th>
                                                    {columns.map((col) => (
                                                        <th
                                                            key={col.key}
                                                            scope="col"
                                                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold dark:text-white sm:pl-0"
                                                        >
                                                            {col.label}
                                                        </th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody className="divide-y divide-gray-300 dark:divide-gray-800">
                                                {searchResults.map(
                                                    (item: any) => {
                                                        const itemId =
                                                            decodeBase64Id(
                                                                item.id,
                                                            );
                                                        const isSale =
                                                            item.__typename ===
                                                            "EbaySaleType";
                                                        const productDetails =
                                                            isSale
                                                                ? item.ebayItem
                                                                : item;

                                                        console.log(
                                                            "Item: ",
                                                            item,
                                                        );
                                                        console.log(
                                                            "Product Details: ",
                                                            productDetails,
                                                        );

                                                        return (
                                                            <tr key={item.id}>
                                                                <td className="hidden whitespace-nowrap py-4 pl-4 pr-3 text-sm dark:text-white sm:pl-0">
                                                                    <div className="relative inline-block h-4 w-4">
                                                                        <label
                                                                            htmlFor={`selectItemCheckbox-${itemId}`}
                                                                            className="absolute inset-0 flex h-full w-full cursor-pointer items-center justify-center rounded-full border-2 border-gray-400 dark:border-gray-600"
                                                                        >
                                                                            <input
                                                                                type="checkbox"
                                                                                checked={selectedItems.includes(
                                                                                    itemId,
                                                                                )}
                                                                                onChange={() =>
                                                                                    handleCheckboxChange(
                                                                                        itemId,
                                                                                    )
                                                                                }
                                                                                className="peer absolute h-5 w-5 opacity-0"
                                                                                id={`selectItemCheckbox-${itemId}`}
                                                                            />
                                                                            <svg
                                                                                className="opacity-0 peer-checked:opacity-100"
                                                                                viewBox="0 0 24 24"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <g
                                                                                    id="SVGRepo_bgCarrier"
                                                                                    strokeWidth="0"
                                                                                ></g>
                                                                                <g
                                                                                    id="SVGRepo_tracerCarrier"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                ></g>
                                                                                <g id="SVGRepo_iconCarrier">
                                                                                    <path
                                                                                        fillRule="evenodd"
                                                                                        clipRule="evenodd"
                                                                                        d="M1.5 12C1.5 6.20101 6.20101 1.5 12 1.5C17.799 1.5 22.5 6.20101 22.5 12C22.5 17.799 17.799 22.5 12 22.5C6.20101 22.5 1.5 17.799 1.5 12ZM15.7127 10.7197C16.0055 10.4268 16.0055 9.95192 15.7127 9.65903C15.4198 9.36614 14.9449 9.36614 14.652 9.65903L10.9397 13.3713L9.34869 11.7804C9.0558 11.4875 8.58092 11.4875 8.28803 11.7804C7.99514 12.0732 7.99514 12.5481 8.28803 12.841L10.4093 14.9623C10.7022 15.2552 11.1771 15.2552 11.47 14.9623L15.7127 10.7197Z"
                                                                                        fill="#0064d2"
                                                                                    ></path>
                                                                                </g>
                                                                            </svg>
                                                                        </label>
                                                                    </div>
                                                                </td>
                                                                {columns.map(
                                                                    (col) => (
                                                                        <td
                                                                            key={
                                                                                col.key
                                                                            }
                                                                            className="whitespace-nowrap py-4 pl-4 pr-3 text-sm dark:text-white sm:pl-0"
                                                                        >
                                                                            {col.render
                                                                                ? col.render(
                                                                                      productDetails[
                                                                                          col
                                                                                              .key
                                                                                      ],
                                                                                      productDetails,
                                                                                  )
                                                                                : productDetails[
                                                                                      col
                                                                                          .key
                                                                                  ] ||
                                                                                  ""}
                                                                        </td>
                                                                    ),
                                                                )}
                                                            </tr>
                                                        );
                                                    },
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SearchResultsPage;
