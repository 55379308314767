import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useState } from "react";
import { GET_EBAY_ITEM_QUERY } from "@/graphql/queries/ebay/products/ebayProductsQueries";

import ProductDetailsComponent from "@/components/dashboard/products/ProductDetailsComponent";
import ProductTimelineComponent from "@/components/dashboard/products/ProductTimelineComonent";
import ProductTotalsComponent from "@/components/dashboard/products/ProductTotalsComponent";
import EditProductModal from "@/components/dashboard/products/modals/EditProductModal";
import ScreenshotProductModal from "@/components/dashboard/products/modals/ScreenshotProductModal";
import CircularLoader from "@/components/loading/LoadingComponent";

const EbayProductPage: React.FC = () => {
    const { product_id } = useParams<{ product_id: string }>();

    const { data, loading, error } = useQuery(GET_EBAY_ITEM_QUERY, {
        variables: { ebayItemId: product_id },
    });

    const [isEditProductModalOpen, setIsEditProductModalOpen] = useState(false);
    const [isScreenshotModalOpen, setIsScreenshotModalOpen] = useState(false);

    const toggleEditProductModal = () => {
        setIsEditProductModalOpen(!isEditProductModalOpen);
    };

    const toggleScreenshotModal = () => {
        setIsScreenshotModalOpen(!isScreenshotModalOpen);
    };

    const getTextColorBasedOnHex = (hex: string) => {
        // Remove the leading `#` if present
        const sanitizedHex = hex.replace("#", "");
        const r = parseInt(sanitizedHex.substring(0, 2), 16);
        const g = parseInt(sanitizedHex.substring(2, 4), 16);
        const b = parseInt(sanitizedHex.substring(4, 6), 16);

        // Calculate brightness
        const brightness = 0.299 * r + 0.587 * g + 0.114 * b;

        // Return appropriate text color
        return brightness > 128 ? "text-gray-800" : "text-white";
    };

    if (loading) return <CircularLoader />;
    if (error) return <p>Error: {error.message}</p>;

    const product = data?.ebayItem;
    const vatSettings = data?.vatSettingsHistoryForEbayItem;

    return (
        <div className="dark:bg-gray-900">
            <div className="py-2 dark:bg-gray-900">
                <div className="flex items-center justify-between px-2 pb-2 sm:px-2 lg:px-1">
                    <h3 className="text-base font-semibold leading-6 text-gray-900 dark:text-white">
                        {product?.itemName}
                    </h3>
                    <div className="flex items-center">
                        {product.tags.map((tag: any) => (
                            <span
                                key={tag.id}
                                className={`mr-2 rounded-full px-2 py-1 ${getTextColorBasedOnHex(tag.color.hex)}`}
                                style={{ backgroundColor: tag.color.hex }}
                            >
                                {tag.name}
                            </span>
                        ))}
                        <button
                            onClick={toggleEditProductModal}
                            className="mr-2 inline-flex justify-center truncate rounded-lg bg-gray-600 px-4 py-2 text-sm text-white hover:bg-gray-500 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-800"
                        >
                            Edit Product
                        </button>
                        <button
                            onClick={toggleScreenshotModal}
                            className="inline-flex justify-center rounded-lg bg-gray-600 px-4 py-2 text-sm text-white hover:bg-gray-500 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-800"
                        >
                            Share
                        </button>
                    </div>
                </div>
            </div>
            <dl className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-7">
                <ProductDetailsComponent
                    product={product}
                    vatSettings={vatSettings}
                />
                <div className="rounded-lg sm:col-span-5">
                    <ProductTotalsComponent product={product} />
                    <ProductTimelineComponent
                        product={product}
                        vatSettings={vatSettings}
                    />
                </div>
            </dl>
            <EditProductModal
                product={product}
                isOpen={isEditProductModalOpen}
                closeModal={toggleEditProductModal}
                vatSettings={vatSettings}
            />
            <ScreenshotProductModal
                product={product}
                isOpen={isScreenshotModalOpen}
                closeModal={toggleScreenshotModal}
            />
        </div>
    );
};

export default EbayProductPage;
