import React, { useMemo } from "react";
import { useLocation, Link } from "react-router-dom";
import { useQuery } from "@apollo/client";

import LogoComponent from "@/components/logo/LogoComponent";
import { NavigationItem } from "@/types/globalTypes";
import { SidebarNavItem } from "./items/SidebarNavItem";
import { TOTAL_MISSING_DATA_COUNT_QUERY } from "@/graphql/queries/ebay/general/ebayGeneralQueries";

interface SidebarNavProps {
    navigation: NavigationItem[];
}

const SidebarNav: React.FC<SidebarNavProps> = ({ navigation }) => {
    const location = useLocation();

    // Fetch the counts
    const { data } = useQuery(TOTAL_MISSING_DATA_COUNT_QUERY);
    const totalSalesMissingDataCount = data?.totalSalesMissingDataCount ?? 0;
    const totalInventoryMissingDataCount =
        data?.totalInventoryMissingDataCount ?? 0;

    // Transform navigation to insert counts for 'Missing Data'
    const updatedNavigation = useMemo(() => {
        return navigation.map((item) => {
            // Only modify if the item's name is "Inventory" or "Sold"
            // and then specifically target the child named "Missing Data"
            if (item.name === "Inventory") {
                const updatedChildren = item.children.map((child) => {
                    if (child.name === "Missing Data") {
                        return {
                            ...child,
                            count: totalInventoryMissingDataCount,
                        };
                    }
                    return child;
                });
                return { ...item, children: updatedChildren };
            }

            if (item.name === "Sold") {
                const updatedChildren = item.children.map((child) => {
                    if (child.name === "Missing Data") {
                        return { ...child, count: totalSalesMissingDataCount };
                    }
                    return child;
                });
                return { ...item, children: updatedChildren };
            }

            // Otherwise leave this item as-is
            return item;
        });
    }, [
        navigation,
        totalInventoryMissingDataCount,
        totalSalesMissingDataCount,
    ]);

    return (
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
            <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white pb-4 pt-4 dark:bg-gray-800">
                <div className="mt-2 flex h-14 shrink-0 items-center">
                    <Link to="/dashboard" className="mx-auto">
                        <LogoComponent className="mx-auto mt-3 h-24" />
                    </Link>
                </div>
                <nav className="flex flex-1 flex-col px-4 pt-6">
                    <ul
                        role="list"
                        className="flex flex-1 flex-col gap-y-2 rounded-md"
                    >
                        {updatedNavigation.map((item, index) => (
                            <SidebarNavItem
                                key={index}
                                item={item}
                                isActive={item.href === location.pathname}
                                disabled={item.disabled}
                            />
                        ))}
                    </ul>
                </nav>
            </div>
        </div>
    );
};

export default SidebarNav;
