import React, { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { UPDATE_EBAY_ITEM_TAG_MUTATION } from "@/graphql/mutations/ebayMutations"; // Update mutation
import { useMutation } from "@apollo/client";
import { decodeBase64Id } from "@/helpers/common/decodeBase64Id";

interface ModalProps {
    isOpen: boolean;
    closeModal: () => void;
    tag: any; // The tag being edited
}

const EditTagModal: React.FC<ModalProps> = ({ isOpen, closeModal, tag }) => {
    const navigate = useNavigate();

    const [updateItemTag] = useMutation(UPDATE_EBAY_ITEM_TAG_MUTATION); // Use mutation for updating

    // Initialize form fields with the existing tag data
    const [name, setName] = useState(tag.name || "");
    const [description, setDescription] = useState(tag.description || "");
    const [color, setColor] = useState(tag.color.hex || "#495057");
    const [archived, setArchived] = useState(tag.archived || false);

    useEffect(() => {
        // Reset form fields when a new tag is passed
        setName(tag.name || "");
        setDescription(tag.description || "");
        setColor(tag.color.hex || "#495057");
        setArchived(tag.archived || false);
    }, [tag]);

    const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const variables: any = {
            id: decodeBase64Id(tag.id),
            name,
            description,
            color,
            archived,
        };

        try {
            await updateItemTag({ variables });
            closeModal();
            navigate(0); // Refresh the page after editing
        } catch (error) {
            console.error("Error updating eBay item tag:", error);
        }
    };

    return (
        <Transition show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 border-2 bg-white bg-opacity-75 transition-opacity dark:bg-gray-500" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg border-2 border-gray-900 bg-white px-4 pb-4 text-left shadow-xl transition-all dark:border-0 dark:bg-gray-900 sm:my-8 sm:w-full sm:max-w-sm sm:p-6 lg:pt-4">
                                <div className="mb-3">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 dark:text-white"
                                    >
                                        Edit Tag
                                    </Dialog.Title>
                                </div>
                                <div className="mb-3">
                                    <form onSubmit={handleFormSubmit}>
                                        <div className="mt-1">
                                            <label
                                                htmlFor="name"
                                                className="block text-sm font-medium dark:text-white"
                                            >
                                                Name
                                            </label>
                                            <input
                                                type="text"
                                                name="name"
                                                id="name"
                                                value={name}
                                                onChange={(e) =>
                                                    setName(e.target.value)
                                                }
                                                className="mt-1 block w-full rounded-md border border-gray-900 px-3 py-2 shadow-sm focus:border-brand focus:ring-brand sm:text-sm"
                                                maxLength={30}
                                            />
                                        </div>
                                        <div className="mt-4">
                                            <label
                                                htmlFor="description"
                                                className="block text-sm font-medium dark:text-white"
                                            >
                                                Description
                                            </label>
                                            <textarea
                                                name="description"
                                                id="description"
                                                value={description}
                                                onChange={(e) =>
                                                    setDescription(
                                                        e.target.value,
                                                    )
                                                }
                                                className="mt-1 block w-full rounded-md border border-gray-900 px-3 py-2 shadow-sm focus:border-brand focus:ring-brand sm:text-sm"
                                                maxLength={100}
                                            />
                                        </div>
                                        <div className="mt-4">
                                            <label
                                                htmlFor="color"
                                                className="block text-sm font-medium dark:text-white"
                                            >
                                                Color
                                            </label>
                                            <input
                                                type="color"
                                                name="color"
                                                id="color"
                                                value={color}
                                                onChange={(e) =>
                                                    setColor(e.target.value)
                                                }
                                                className="mt-1 block w-1/4 rounded-md border border-gray-900 shadow-sm focus:border-brand focus:ring-brand sm:text-sm"
                                            />
                                        </div>
                                        <div className="mt-4">
                                            <label
                                                htmlFor="archived"
                                                className="mb-2 block text-sm font-medium dark:text-white"
                                            >
                                                Archived
                                            </label>
                                            <label className="relative inline-flex cursor-pointer items-center">
                                                <input
                                                    type="checkbox"
                                                    name="archived"
                                                    id="archived"
                                                    className="peer sr-only"
                                                    checked={archived}
                                                    onChange={(e) =>
                                                        setArchived(
                                                            e.target.checked,
                                                        )
                                                    }
                                                />
                                                <div className="peer h-6 w-11 rounded-full bg-gray-300 transition-colors duration-200 peer-checked:bg-brand peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-brand dark:bg-gray-700" />
                                                <span className="absolute left-0.5 top-0.5 h-5 w-5 transform rounded-full border border-gray-300 bg-white transition-transform duration-200 peer-checked:translate-x-5" />
                                            </label>
                                        </div>

                                        <div className="mt-4">
                                            <button
                                                type="submit"
                                                className="flex w-full justify-center rounded-md bg-brand px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm hover:bg-brand focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand dark:text-white"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </form>
                                    <div className="mt-4">
                                        <button
                                            type="button"
                                            className="flex w-full justify-center rounded-md border-2 border-gray-900 px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-700 dark:border-0 dark:bg-gray-800 dark:text-white dark:hover:bg-gray-700"
                                            onClick={closeModal}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export default EditTagModal;
