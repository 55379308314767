import LogoComponent from "@/components/logo/LogoComponent";
import { INITIATE_EBAY_AUTH_MUTATION } from "@/graphql/mutations/oauthMutations";
import { useMutation } from "@apollo/client/react";
import { useAuth } from "@/hooks/auth/useAuth";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export default function ReconnectEbayPage() {
    const [initiateEbayAuth] = useMutation(INITIATE_EBAY_AUTH_MUTATION);
    const { ebayAccount, loading, logoutUser } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (
            !loading &&
            ebayAccount &&
            ebayAccount.isActive &&
            !ebayAccount.invalidToken
        ) {
            navigate("/dashboard");
        }
    }, [ebayAccount, navigate, loading]);

    const initiateOauth = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            const { data } = await initiateEbayAuth();

            if (data.initiateOauth.loginUrl) {
                window.location.href = data.initiateOauth.loginUrl;
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <LogoComponent className="mx-auto h-20 w-auto" />
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight dark:text-white">
                    Unfortunately the credentials for your eBay account have
                    expired. Please re-authenticate to continue using
                    FlipLytics.
                </h2>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                    <form className="space-y-6" onSubmit={initiateOauth}>
                        <div>
                            <button
                                type="submit"
                                className="flex w-full justify-center rounded-md border border-transparent bg-brand px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-brand/80 focus:outline-none focus:ring-2 focus:ring-brand focus:ring-offset-2"
                            >
                                Authenticate with eBay
                            </button>
                        </div>
                    </form>
                </div>

                <div className="mt-10">
                    <p className="text-center text-sm dark:text-gray-400">
                        Need help?{" "}
                        <a
                            href="https://fliplytics.co.uk/contact"
                            className="hover:text-brand-light font-semibold text-brand"
                        >
                            Contact our support team
                        </a>
                        .
                    </p>
                    <p className="mt-3 text-center text-sm dark:text-gray-400">
                        <a
                            href="#"
                            onClick={logoutUser}
                            className="hover:text-brand-light font-semibold text-brand"
                        >
                            Sign out
                        </a>
                    </p>
                </div>
            </div>
        </div>
    );
}
