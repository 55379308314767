import UnsoldEbayItemsTableComponent from "@/components/dashboard/inventory/UnsoldEbayItemsTableComponent";
import { Tooltip } from "react-tooltip";
import { decodeBase64Id } from "@/helpers/common/decodeBase64Id";
import { Link } from "react-router-dom";

const unlistedProductsColumns = [
    {
        key: "itemName",
        label: "Product",
        render: (value: string, item?: any) => (
            <div className="flex items-center truncate">
                <Link
                    data-tooltip-id="tooltip"
                    data-tooltip-content={value}
                    to={`/dashboard/products/${decodeBase64Id(item.id)}`}
                    className="flex items-center truncate hover:underline"
                >
                    <img
                        src={item.imageUrl || "/no-image-available.jpg"}
                        alt={item.itemName}
                        className="mr-4 h-9 w-10 min-w-10 rounded-md object-cover"
                    />
                    <div className="truncate">{value}</div>
                </Link>
                <Tooltip
                    id="tooltip"
                    delayShow={500}
                    place="bottom"
                    className="!rounded-md !bg-gray-300 !text-black dark:!bg-gray-800 dark:!text-white 2xl:!hidden"
                />
            </div>
        ),
    },
    {
        key: "quantity",
        label: "Quantity",
        render: (value: number) => (value != null ? value : ""),
    },
    {
        key: "listedAt",
        label: "Previously Listed",
        render: (value: string) =>
            value ? new Date(value).toLocaleDateString() : "",
    },
    {
        key: "costOfGoodsPerItem",
        label: "Cost",
        render: (value: number) => (value != null ? `£${value}` : ""),
    },
    {
        key: "listedPrice",
        label: "Previously Listed Price",
        render: (value: number) => (value != null ? `£${value}` : ""),
    },
];

export default function UnlistedProductsPage() {
    return (
        <>
            <UnsoldEbayItemsTableComponent columns={unlistedProductsColumns} />
        </>
    );
}
