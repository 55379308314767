import { NavigationItem } from "@/types/globalTypes";

export const navigationData: NavigationItem[] = [
    {
        name: "Dashboard",
        href: "",
        icon: "home-outline",
        children: [],
    },
    {
        name: "Inventory",
        href: "",
        icon: "fi-package",
        children: [
            {
                name: "Active Products",
                href: "/dashboard/inventory/active-products",
            },
            {
                name: "Unlisted Products",
                href: "/dashboard/inventory/unlisted-products",
            },
            {
                name: "Missing Data",
                href: "/dashboard/inventory/missing-data",
                count: 0,
            },
        ],
    },
    {
        name: "Sold",
        href: "",
        icon: "currency-pound-outline",
        children: [
            {
                name: "All Sold",
                href: "/dashboard/sold/all-sold",
            },
            // {
            //     name: "Ready to Ship",
            //     href: "/dashboard/sold/ready-to-ship",
            // },
            // {
            //     name: "Shipped",
            //     href: "/dashboard/sold/shipped",
            // },
            {
                name: "Missing Data",
                href: "/dashboard/sold/missing-data",
                count: 0,
            },
        ],
    },
    {
        name: "Analytics",
        href: "/dashboard/analytics",
        icon: "chart-bar-outline",
        disabled: true,
        children: [],
    },
    {
        name: "Manage",
        href: "",
        icon: "cog-6-tooth-outline",
        children: [
            {
                name: "Tags",
                href: "/dashboard/manage/tags",
            },
            {
                name: "Source Locations",
                href: "/dashboard/manage/source-locations",
            },
            {
                name: "Storage Locations",
                href: "/dashboard/manage/storage-locations",
                disabled: true,
            },
        ],
    },
];
