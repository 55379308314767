import React from "react";
import { Tooltip } from "react-tooltip";

interface StatsComponentProps {
    statsData: Record<string, number>;
}

interface StatItem {
    name: string;
    stat: string;
    prefix: string;
    suffix: string;
    pill?: string; // Optional pill property
    tooltip?: string; // Optional tooltip property
}

const StatsComponent: React.FC<StatsComponentProps> = ({ statsData }) => {
    const getStatClass = (value: number) => {
        if (isNaN(value)) {
            return "text-gray-900 dark:text-white";
        }
        return value >= 0 ? "text-green-500" : "text-red-500";
    };

    const stats: StatItem[] = [
        {
            name: "Sales Revenue",
            stat: statsData.salesRevenue.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }),
            prefix: "£",
            suffix: "",
            tooltip: "Total eBay sales including postage charged",
        },
        {
            name: "Total Profit",
            stat: statsData.totalProfit.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }),
            prefix: "£",
            suffix: "",
            tooltip: "Total profit including unsold item COGS",
        },
        {
            name: "Profit on Sales",
            stat: statsData.totalProfitOnSales.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }),
            prefix: "£",
            suffix: "",
            tooltip: "Profit from total sales excluding unsold item COGS",
        },
        {
            name: "Avg. Transaction Value",
            stat: statsData.avgTransactionValue.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }),
            prefix: "£",
            suffix: "",
            tooltip: "Total sales/Total volume of sales",
        },
    ];

    const stats2: StatItem[] = [
        {
            name: "Return on Investment",
            stat: statsData.returnOnInvestment.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }),
            prefix: "",
            suffix: "%",
            tooltip: "Profit/COG x 100",
        },
        // {
        //     name: "Profit Per Hour",
        //     stat: "N/A",
        //     prefix: "",
        //     suffix: "",
        //     pill: "Coming Soon", // Add pill here
        // },
        {
            name: "Avg. Profit Per Transaction",
            stat: statsData.avgProfitPerTransaction.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }),
            prefix: "£",
            suffix: "",
            tooltip: "Total profit/Total number of transactions",
        },
        {
            name: "Products Sold",
            stat: statsData.productsSold.toLocaleString(),
            prefix: "",
            suffix: "",
            tooltip: "Total number of products sold",
        },
        {
            name: "Avg. Time to Sell",
            stat: statsData.avgTimeToSell.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }),
            prefix: "",
            suffix: " days",
            tooltip: "Measured from when the product goes live",
        },
    ];

    const stats3: StatItem[] = [
        {
            name: "Newly Listed",
            stat: statsData.newListings.toLocaleString(),
            prefix: "",
            suffix: "",
            tooltip:
                "Total number of new listings within the selected date range",
        },
        {
            name: "Active Listings",
            stat: statsData.activeListings.toLocaleString(),
            prefix: "",
            suffix: "",
            tooltip: "Total number of active listings",
        },
        {
            name: "Current Listed Value",
            stat: statsData.currentListedValue.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }),
            prefix: "£",
            suffix: "",
            tooltip: "Total value of all active listings",
        },
        {
            name: "Avg. Time to List",
            stat: statsData.avgTimeToList.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }),
            prefix: "",
            suffix: " days",
            tooltip:
                "Measured from purchase date to live on ebay. Items require a purchase date.",
        },
    ];

    return (
        <div>
            <dl className="mt-5 grid grid-cols-2 gap-5 sm:grid-cols-4">
                {stats.map((item) => (
                    <div
                        key={item.name}
                        className="relative overflow-hidden rounded-lg bg-white px-4 py-5 shadow dark:bg-gray-800 sm:p-6"
                    >
                        <div className="flex items-start justify-between">
                            <dt className="truncate text-sm font-semibold text-gray-600 dark:text-gray-300">
                                {item.name}
                            </dt>
                            {item.tooltip && (
                                <svg
                                    data-tooltip-id="stats-tooltip"
                                    data-tooltip-content={item.tooltip}
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="h-4 w-4 text-gray-400 hover:cursor-pointer"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                                    />
                                </svg>
                            )}
                        </div>
                        <div className="grid">
                            {item.pill && (
                                <span className="col-start-1 col-end-2 row-start-1 row-end-3 mt-2 flex w-20 items-center justify-center rounded-full bg-gray-200 px-1 py-1 text-[10px] font-medium text-gray-800 dark:bg-gray-700 dark:text-gray-300">
                                    {item.pill}
                                </span>
                            )}
                            {/* Conditionally render the stat based on the presence of a pill */}
                            {!item.pill && (
                                <dd
                                    className={`col-start-1 col-end-2 row-start-1 row-end-3 font-semibold tracking-tight sm:text-lg md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl ${getStatClass(parseFloat(item.stat))}`}
                                >
                                    {item.prefix}
                                    {item.stat}
                                    {item.suffix}
                                </dd>
                            )}
                        </div>
                    </div>
                ))}
            </dl>
            <dl className="mt-5 grid grid-cols-2 gap-5 sm:grid-cols-4">
                {stats2.map((item) => (
                    <div
                        key={item.name}
                        className="relative overflow-hidden rounded-lg bg-white px-4 py-5 shadow dark:bg-gray-800 sm:p-6"
                    >
                        <div className="flex items-start justify-between">
                            <dt className="truncate text-sm font-semibold text-gray-600 dark:text-gray-300">
                                {item.name}
                            </dt>
                            {item.tooltip && (
                                <svg
                                    data-tooltip-id="stats-tooltip"
                                    data-tooltip-content={item.tooltip}
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="h-4 w-4 text-gray-400 hover:cursor-pointer"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                                    />
                                </svg>
                            )}
                        </div>
                        <div className="grid">
                            {item.pill && (
                                <span className="col-start-1 col-end-2 row-start-1 row-end-3 mt-2 flex w-20 items-center justify-center rounded-full bg-gray-200 px-1 py-1 text-[10px] font-medium text-gray-800 dark:bg-gray-700 dark:text-gray-300">
                                    {item.pill}
                                </span>
                            )}
                            {/* Conditionally render the stat based on the presence of a pill */}
                            {!item.pill && (
                                <dd
                                    className={`col-start-1 col-end-2 row-start-1 row-end-3 font-semibold tracking-tight sm:text-lg md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl ${getStatClass(parseFloat(item.stat))}`}
                                >
                                    {item.prefix}
                                    {item.stat}
                                    {item.suffix}
                                </dd>
                            )}
                        </div>
                    </div>
                ))}
            </dl>
            <dl className="mt-5 grid grid-cols-2 gap-5 sm:grid-cols-4">
                {stats3.map((item) => (
                    <div
                        key={item.name}
                        className="relative overflow-hidden rounded-lg bg-white px-4 py-5 shadow dark:bg-gray-800 sm:p-6"
                    >
                        <div className="flex items-start justify-between">
                            <dt className="truncate text-sm font-semibold text-gray-600 dark:text-gray-300">
                                {item.name}
                            </dt>
                            {item.tooltip && (
                                <svg
                                    data-tooltip-id="stats-tooltip"
                                    data-tooltip-content={item.tooltip}
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="h-4 w-4 text-gray-400 hover:cursor-pointer"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                                    />
                                </svg>
                            )}
                        </div>
                        <div className="grid">
                            {item.pill && (
                                <span className="col-start-1 col-end-2 row-start-1 row-end-3 mt-2 flex w-20 items-center justify-center rounded-full bg-gray-200 px-1 py-1 text-[10px] font-medium text-gray-800 dark:bg-gray-700 dark:text-gray-300">
                                    {item.pill}
                                </span>
                            )}
                            {/* Conditionally render the stat based on the presence of a pill */}
                            {!item.pill && (
                                <dd
                                    className={`col-start-1 col-end-2 row-start-1 row-end-3 font-semibold tracking-tight sm:text-lg md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl ${getStatClass(parseFloat(item.stat))}`}
                                >
                                    {item.prefix}
                                    {item.stat}
                                    {item.suffix}
                                </dd>
                            )}
                        </div>
                    </div>
                ))}
            </dl>
            <Tooltip
                id="stats-tooltip"
                openOnClick
                place="top"
                className="!max-w-md !rounded-md !bg-gray-300 !text-black dark:!bg-gray-700 dark:!text-white"
            />
        </div>
    );
};

export default StatsComponent;
