import React, { useEffect, useState } from "react";
import {
    ExclamationTriangleIcon,
    XMarkIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

interface TasksComponentProps {
    missingDataCountData: {
        salesMissingDataCount: number;
        inventoryMissingDataCount: number;
    };
}

interface TaskBarProps {
    count: number;
    text: string;
    link: string;
    taskType: "inventory" | "sales";
    onDismiss: (taskType: "inventory" | "sales") => void;
}

const WEEK_IN_MS = 7 * 24 * 60 * 60 * 1000;

// Utility function to parse or create a localStorage item
function loadDismissals() {
    try {
        const stored = localStorage.getItem("dismissTasks");
        if (!stored) return {};
        return JSON.parse(stored);
    } catch (err) {
        console.error("Failed to load dismissTasks from localStorage:", err);
        return {};
    }
}

function saveDismissals(dismissals: Record<string, number>) {
    localStorage.setItem("dismissTasks", JSON.stringify(dismissals));
}

const TaskBar: React.FC<TaskBarProps> = ({
    count,
    text,
    link,
    taskType,
    onDismiss,
}) => (
    <div className="relative my-2 flex items-center rounded-lg bg-gray-200 p-3 dark:bg-gray-700">
        <div className="mr-2 rounded-full bg-brand_yellow p-1">
            <ExclamationTriangleIcon className="h-5 w-5 text-yellow-800" />
        </div>
        <div className="text-sm dark:text-white/50">
            <span className="font-bold">{count.toLocaleString()}</span> {text}{" "}
            <Link className="text-brand" to={link}>
                Fix now.
            </Link>
        </div>
        {/* Dismiss Button */}
        <button
            onClick={() => onDismiss(taskType)}
            className="absolute right-2 top-2 rounded p-1 hover:bg-gray-300 dark:hover:bg-gray-600"
            title="Dismiss for a week"
        >
            <XMarkIcon className="h-4 w-4 text-gray-800 dark:text-gray-300" />
        </button>
    </div>
);

const TasksComponent: React.FC<TasksComponentProps> = ({
    missingDataCountData,
}) => {
    const [dismissals, setDismissals] = useState<Record<string, number>>({});

    const salesMissingDataCount = missingDataCountData?.salesMissingDataCount;
    const inventoryMissingDataCount =
        missingDataCountData?.inventoryMissingDataCount;

    useEffect(() => {
        // Load individual dismissals from localStorage
        const loadedDismissals = loadDismissals();
        setDismissals(loadedDismissals);
    }, []);

    const handleDismiss = (taskType: "inventory" | "sales") => {
        const newDismissals = {
            ...dismissals,
            [taskType]: Date.now() + WEEK_IN_MS, // Set a future dismissal timestamp
        };
        setDismissals(newDismissals);
        saveDismissals(newDismissals);
    };

    const isDismissed = (taskType: "inventory" | "sales") => {
        const dismissalTimestamp = dismissals[taskType];
        if (!dismissalTimestamp) {
            return false;
        }
        return Date.now() < dismissalTimestamp;
    };

    // For convenience, define a quick check:
    const inventoryHiddenOrNone =
        !inventoryMissingDataCount || isDismissed("inventory");
    const salesHiddenOrNone = !salesMissingDataCount || isDismissed("sales");

    // If everything is either 0 or dismissed, we'll show a message
    const noTasksToShow = inventoryHiddenOrNone && salesHiddenOrNone;

    // Function to "unhide" all tasks
    const unhideAllTasks = () => {
        localStorage.removeItem("dismissTasks");
        setDismissals({});
    };

    return (
        <div className="self-start rounded-lg bg-white px-4 py-5 shadow dark:bg-gray-800 sm:col-span-4 sm:row-start-2 sm:p-6 2xl:col-span-2">
            <div className="text-sm font-semibold text-gray-600 dark:text-gray-300">
                Tasks
            </div>

            {/* If both tasks are hidden or zero, show a fallback message */}
            {noTasksToShow ? (
                <div className="mt-2 text-sm text-gray-600 dark:text-gray-300">
                    No tasks to show.{" "}
                    <button
                        onClick={unhideAllTasks}
                        className="text-brand underline"
                    >
                        Unhide all tasks
                    </button>
                </div>
            ) : (
                <>
                    {/* Render TaskBar only if inventoryMissingDataCount > 0 and not dismissed */}
                    {inventoryMissingDataCount > 0 &&
                        !isDismissed("inventory") && (
                            <TaskBar
                                count={inventoryMissingDataCount}
                                text="items in your inventory have missing data."
                                link="/dashboard/inventory/missing-data"
                                taskType="inventory"
                                onDismiss={handleDismiss}
                            />
                        )}

                    {/* Render TaskBar only if salesMissingDataCount > 0 and not dismissed */}
                    {salesMissingDataCount > 0 && !isDismissed("sales") && (
                        <TaskBar
                            count={salesMissingDataCount}
                            text="of your sold items have missing data."
                            link="/dashboard/sold/missing-data"
                            taskType="sales"
                            onDismiss={handleDismiss}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default TasksComponent;
