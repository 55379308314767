import React from "react";
import { Link } from "react-router-dom";
import { NavigationChild } from "@/types/globalTypes";
import { classNames } from "@/helpers/common/classNames";

interface NavItemProps {
    item: NavigationChild;
    isActive: boolean;
    disabled?: boolean;
}

export const SidebarNavItemChild: React.FC<NavItemProps> = ({
    item,
    isActive,
    disabled = false,
}) => {
    return (
        <li>
            <Link
                to={disabled ? "#" : item.href}
                onClick={(e) => disabled && e.preventDefault()}
                className={classNames(
                    isActive
                        ? "bg-brand text-white"
                        : "text-gray-700 hover:bg-brand hover:text-white dark:text-gray-200 dark:hover:text-white",
                    disabled
                        ? "group flex cursor-not-allowed items-center justify-between gap-x-3 rounded-md px-3 py-2 text-sm font-medium leading-6 opacity-50"
                        : "group flex items-center justify-between gap-x-3 rounded-md px-3 py-2 text-sm font-medium leading-6",
                )}
                aria-disabled={disabled}
                tabIndex={disabled ? -1 : 0} // Optional: Make the link unfocusable if disabled
            >
                <span className="flex items-center gap-x-3">{item.name}</span>
                {item.count != null && item.count > 0 && (
                    <span className="w-19 ml-2 inline-flex h-6 items-center justify-center rounded-full bg-red-500 p-1 text-[9px] font-medium text-white dark:bg-red-400 dark:text-white">
                        {item.count}
                    </span>
                )}
                {disabled && (
                    <span className="ml-2 inline-flex h-6 w-16 items-center justify-center rounded-full bg-gray-200 p-1 text-[8px] font-medium text-gray-800 dark:bg-gray-600 dark:text-gray-100">
                        Coming Soon
                    </span>
                )}
            </Link>
        </li>
    );
};
