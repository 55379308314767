import React, {
    Fragment,
    useRef,
    useEffect,
    useState,
    useCallback,
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useMutation } from "@apollo/client";
import LogoComponent from "@/components/logo/LogoComponent";
import { GENERATE_SCREENSHOT_MUTATION } from "@/graphql/mutations/generalMutations";
import { decodeBase64Id } from "@/helpers/common/decodeBase64Id";

interface ModalProps {
    isOpen: boolean;
    closeModal: () => void;
    product: any;
}

const ScreenshotProductModal: React.FC<ModalProps> = ({
    isOpen,
    closeModal,
    product,
}) => {
    const imageRef = useRef<HTMLImageElement>(null);
    const [isPortrait, setIsPortrait] = useState<boolean>(false);
    const [imageLoaded, setImageLoaded] = useState<boolean>(false);

    const [generateScreenshot, { loading, error }] = useMutation(
        GENERATE_SCREENSHOT_MUTATION,
    );

    const handleImageLoad = useCallback(() => {
        if (imageRef.current) {
            const { naturalWidth, naturalHeight } = imageRef.current;
            setIsPortrait(naturalHeight > naturalWidth);
            setImageLoaded(true);
        }
    }, []);

    useEffect(() => {
        if (isOpen) {
            setImageLoaded(false);
            if (imageRef.current && imageRef.current.complete) {
                handleImageLoad();
            }
        }
    }, [isOpen, handleImageLoad]);

    const handleScreenshot = async () => {
        try {
            const { data } = await generateScreenshot({
                variables: { ebayItemId: decodeBase64Id(product?.id) },
            });

            if (data?.generateScreenshot?.imageBase64) {
                const link = document.createElement("a");
                link.href = `data:image/jpeg;base64,${data.generateScreenshot.imageBase64}`;
                link.download = `${product?.itemName || "screenshot"}.jpg`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        } catch (err) {
            console.error("Error generating screenshot:", err);
        }
    };

    const costOfGoodsPerItem = product?.costOfGoodsPerItem || 0;
    const totalRevenue = product?.profitAndRoi?.totalRevenue || 0;
    const totalProfit = product?.profitAndRoi?.totalProfitLoss || 0;
    const totalCostOfGoods = product?.profitAndRoi?.totalCosts || 0;
    const totalEbayFees = product?.profitAndRoi?.totalEbayFees || 0;
    const totalROI = parseFloat(
        product?.profitAndRoi?.totalReturnOnInvestment || 0,
    ).toFixed(1);

    const stats = [
        {
            name: "Cost of Goods",
            stat: "£" + costOfGoodsPerItem,
        },
        {
            name: "Revenue",
            stat: "£" + totalRevenue,
        },
        {
            name: "eBay Fees",
            stat: "£" + totalEbayFees,
        },
        {
            name: "Profit",
            stat: "£" + totalProfit,
        },
        {
            name: "Total Costs",
            stat: "£" + totalCostOfGoods,
        },
        {
            name: "ROI",
            stat: totalROI + "%",
        },
    ];

    const getStatClass = (name: string, value: number) => {
        if (name === "Revenue") return "text-green-500";
        if (
            name === "Cost of Goods" ||
            name === "eBay Fees" ||
            name === "Total Costs"
        )
            return "text-red-500";
        return value >= 0 ? "text-green-500" : "text-red-500";
    };

    const isMobile = window.innerWidth <= 768;

    return (
        <Transition show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 border-2 bg-white bg-opacity-75 transition-opacity dark:bg-gray-500 dark:opacity-75" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative mx-4 transform overflow-hidden rounded-lg border-2 border-gray-900 bg-white text-left shadow-xl transition-all dark:border-0 dark:bg-gray-800 sm:my-8 sm:w-full sm:max-w-3xl sm:p-6 lg:pt-4">
                                <div className="mb-3">
                                    <div className="">
                                        <dd className="bg-white px-4 pt-4 text-3xl font-semibold tracking-tight text-gray-900 dark:bg-gray-800 dark:text-white">
                                            <h3 className="mb-4 text-center text-lg font-semibold leading-6 text-gray-900 dark:text-white">
                                                {isMobile
                                                    ? "Download Screenshot"
                                                    : product?.itemName}
                                            </h3>
                                            {isMobile ? (
                                                <>
                                                    <p className="mb-6 text-center text-sm text-gray-500 dark:text-white">
                                                        The screenshot preview
                                                        is not available on
                                                        mobile devices. Please
                                                        click the Download
                                                        button below to get the
                                                        screenshot.
                                                    </p>
                                                    <div className="mb-6" />
                                                </>
                                            ) : (
                                                <div className="grid grid-cols-2 gap-5">
                                                    <div>
                                                        <div className="flex items-center justify-center">
                                                            <img
                                                                ref={imageRef}
                                                                src={
                                                                    product?.imageUrl
                                                                }
                                                                alt={
                                                                    product?.itemName
                                                                }
                                                                className="mb-4"
                                                                onLoad={
                                                                    handleImageLoad
                                                                }
                                                                decoding="sync"
                                                            />
                                                        </div>
                                                        {!isPortrait &&
                                                            imageLoaded && (
                                                                <div className="mb-4 mt-4 flex justify-center">
                                                                    <LogoComponent
                                                                        width={
                                                                            100
                                                                        }
                                                                        height={
                                                                            100
                                                                        }
                                                                    />
                                                                </div>
                                                            )}
                                                    </div>
                                                    <div>
                                                        <dl className="grid grid-cols-2 gap-5">
                                                            {stats.map(
                                                                (
                                                                    item,
                                                                    index,
                                                                ) => (
                                                                    <div
                                                                        key={
                                                                            item.name
                                                                        }
                                                                        className={`overflow-hidden rounded-lg bg-white p-5 shadow dark:bg-gray-700 sm:p-6 ${
                                                                            index ===
                                                                                stats.length -
                                                                                    1 ||
                                                                            index ===
                                                                                stats.length -
                                                                                    2
                                                                                ? "mb-5"
                                                                                : ""
                                                                        }`}
                                                                    >
                                                                        <dt className="truncate text-center text-sm font-medium text-gray-500 dark:text-white/50">
                                                                            {
                                                                                item.name
                                                                            }
                                                                        </dt>
                                                                        <dd
                                                                            className={`mt-1 text-center text-3xl font-semibold tracking-tight ${
                                                                                item.name ===
                                                                                    "Profit" ||
                                                                                item.name ===
                                                                                    "ROI"
                                                                                    ? getStatClass(
                                                                                          item.name,
                                                                                          parseFloat(
                                                                                              item.stat.replace(
                                                                                                  /[£%]/g,
                                                                                                  "",
                                                                                              ),
                                                                                          ),
                                                                                      )
                                                                                    : getStatClass(
                                                                                          item.name,
                                                                                          0,
                                                                                      )
                                                                            }`}
                                                                        >
                                                                            {
                                                                                item.stat
                                                                            }
                                                                        </dd>
                                                                    </div>
                                                                ),
                                                            )}
                                                        </dl>
                                                        {isPortrait &&
                                                            imageLoaded && (
                                                                <div className="mt-4 flex justify-center">
                                                                    <LogoComponent
                                                                        width={
                                                                            100
                                                                        }
                                                                        height={
                                                                            100
                                                                        }
                                                                    />
                                                                </div>
                                                            )}
                                                    </div>
                                                </div>
                                            )}
                                        </dd>
                                        <div className="flex gap-4 px-4">
                                            <button
                                                type="button"
                                                className="flex-1 justify-center rounded-md border-2 bg-brand px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-700 dark:border-0"
                                                onClick={handleScreenshot}
                                                disabled={loading}
                                            >
                                                {loading
                                                    ? "Generating..."
                                                    : "Download"}
                                            </button>
                                            <button
                                                type="button"
                                                className="flex-1 justify-center rounded-md border-2 bg-gray-200 px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-700 dark:border-0 dark:bg-gray-600 dark:text-white dark:hover:bg-gray-500"
                                                onClick={closeModal}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                        {error && (
                                            <p className="mt-2 text-center text-sm text-red-500">
                                                Error generating screenshot.
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export default ScreenshotProductModal;
