import { gql } from "@apollo/client";

export const GET_EBAY_ACCOUNT_SETTINGS_QUERY = gql`
    query GetEbayAccountSettingsQuery {
        ebayAccountSettings {
            id
            matchPostagePriceWithCost
            screenshotTextAlignment
            defaultPackingCostPerItem
            defaultPostageCost
        }
    }
`;

export const GET_VAT_SETTINGS_QUERY = gql`
    query GetVatSettingsQuery {
        vatSettingsHistory {
            id
            createdAt
            isVatRegistered
            vatRate
            vatType
            vatStartDate
            canClaimVatOnCost
        }
    }
`;
