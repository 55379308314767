import React from "react";

const alertStyles: Record<"success" | "error" | "warning", string> = {
    success: "border border-green-400 bg-green-100 text-green-700",
    warning: "border border-yellow-400 bg-yellow-100 text-yellow-700",
    error: "border border-red-400 bg-red-100 text-red-700",
};

const closeButtonStyles: Record<"success" | "error" | "warning", string> = {
    success: "text-green-700 hover:text-green-800",
    warning: "text-yellow-700 hover:text-yellow-800",
    error: "text-red-700 hover:text-red-800",
};

export const Alert: React.FC<{
    type: "success" | "error" | "warning";
    message: string;
    onClose: () => void;
}> = ({ type, message, onClose }) => {
    const baseClasses =
        "mb-4 rounded-md px-4 py-3 shadow-md flex items-center justify-between";

    return (
        <div className={`${baseClasses} ${alertStyles[type]}`}>
            <span>{message}</span>
            <button
                onClick={onClose}
                className={`ml-4 font-bold ${closeButtonStyles[type]}`}
            >
                &times;
            </button>
        </div>
    );
};
