import TagsTableComponent from "@/components/dashboard/manage/tags/TagsTableComponent";

const tagsColumns = [
    {
        key: "name",
        label: "Tag Name",
        render: (value: string) => value,
    },
    {
        key: "color",
        label: "Tag Colour",
        render: (_value: string, item: any) => (
            <div
                className="h-6 w-6 rounded-full"
                style={{ backgroundColor: item.color.hex }}
            ></div>
        ),
    },
    {
        key: "itemCount",
        label: "No. of Items",
        render: (value: number) => value,
    },
    {
        key: "archived",
        label: "Archived",
        render: (value: boolean) => (value ? "Yes" : "No"),
    },
];

export default function TagsPage() {
    return (
        <>
            <TagsTableComponent columns={tagsColumns} />
        </>
    );
}
