import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import {
    GET_EBAY_ITEM_SOURCE_LOCATIONS_QUERY,
    GET_EBAY_ITEM_BRANDS_QUERY,
    GET_EBAY_ITEM_CATEGORIES_QUERY,
    GET_EBAY_ITEM_TAGS_QUERY,
} from "@/graphql/queries/ebay/general/ebayGeneralQueries";
import useActiveEbayItems from "@/hooks/inventory/useActiveEbayItems";
import { decodeBase64Id } from "@/helpers/common/decodeBase64Id";
import BulkEditEbayItemsModal from "./modals/BulkEditEbayItemsModal";
import CircularLoader from "@/components/loading/LoadingComponent";
import TableFilters from "../dash/widgets/TableFiltersComponent";

interface Column {
    key: string;
    label: string;
    render?: (value?: any, item?: any) => React.ReactNode;
}

interface TableComponentProps {
    columns: Column[];
}

const ActiveEbayItemsTableComponent: React.FC<TableComponentProps> = ({
    columns,
}) => {
    const {
        data,
        loading,
        error,
        filters,
        setFilters,
        handleNextPage,
        handlePreviousPage,
        pageInfo,
        resetFilters,
        applyFilters,
    } = useActiveEbayItems();

    const { data: tagsData } = useQuery(GET_EBAY_ITEM_TAGS_QUERY);
    const { data: categoriesData } = useQuery(GET_EBAY_ITEM_CATEGORIES_QUERY);
    const { data: brandsData } = useQuery(GET_EBAY_ITEM_BRANDS_QUERY);
    const { data: sourceLocationsData } = useQuery(
        GET_EBAY_ITEM_SOURCE_LOCATIONS_QUERY,
    );

    const [showFilters, setShowFilters] = useState(false);
    const [selectedItems, setSelectedItems] = useState<string[]>([]);
    const [isBulkEditModalOpen, setBulkEditModalOpen] = useState(false);

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    ) => {
        const { name, value } = e.target;
        setFilters(name, value === "" ? null : value);
    };

    const toggleFilters = () => {
        setShowFilters((prevShowFilters) => !prevShowFilters);
    };

    const handleCheckboxChange = (id: string) => {
        setSelectedItems((prevSelectedItems) =>
            prevSelectedItems.includes(id)
                ? prevSelectedItems.filter((itemId) => itemId !== id)
                : [...prevSelectedItems, id],
        );
    };

    const openBulkEditModal = () => {
        setBulkEditModalOpen(true);
    };

    const closeBulkEditModal = () => {
        setBulkEditModalOpen(false);
    };

    if (loading) return <CircularLoader />;
    if (error) return <p>Error: {error.message}</p>;

    const products = data?.activeEbayItems?.edges || [];

    return (
        <div className="h-full dark:bg-gray-900">
            <div className="py-2 dark:bg-gray-900">
                <div className="px-2 sm:px-2 lg:px-1">
                    <div className="flow-root">
                        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                {/* Add overflow-x-auto for horizontal scroll */}
                                <div className="h-[calc(100vh-10rem)] overflow-x-auto overflow-y-hidden">
                                    <div className="h-[calc(100vh-10rem)] overflow-y-auto">
                                        <table className="min-w-[900px] table-auto divide-y divide-gray-400 dark:divide-gray-700 sm:min-w-full">
                                            <thead className="sticky top-0 z-10 bg-gray-200 dark:bg-gray-900">
                                                <tr>
                                                    <th
                                                        colSpan={
                                                            columns.length + 1
                                                        }
                                                    >
                                                        <div className="flex items-center justify-between px-2 pb-2 sm:px-2 lg:px-1">
                                                            <h3 className="text-base font-bold leading-6 text-gray-900 dark:text-white">
                                                                Active Products
                                                            </h3>
                                                            <div className="flex space-x-2">
                                                                <TableFilters
                                                                    dateFilterType="listedAt"
                                                                    initialStartDate={
                                                                        new Date(
                                                                            filters.listedAt_Gte,
                                                                        )
                                                                    }
                                                                    initialEndDate={
                                                                        new Date(
                                                                            filters.listedAt_Lte,
                                                                        )
                                                                    }
                                                                    setFilters={
                                                                        setFilters
                                                                    }
                                                                    applyFilters={
                                                                        applyFilters
                                                                    }
                                                                    resetFilters={
                                                                        resetFilters
                                                                    }
                                                                    filters={
                                                                        filters
                                                                    }
                                                                    showFilters={
                                                                        showFilters
                                                                    }
                                                                    toggleFilters={
                                                                        toggleFilters
                                                                    }
                                                                />
                                                                <button
                                                                    onClick={
                                                                        openBulkEditModal
                                                                    }
                                                                    disabled={
                                                                        selectedItems.length ===
                                                                        0
                                                                    }
                                                                    className={`px-4 py-2 ${
                                                                        selectedItems.length >
                                                                        0
                                                                            ? "bg-brand text-white"
                                                                            : "cursor-not-allowed bg-white text-gray-300 dark:bg-gray-700 dark:text-gray-900"
                                                                    } rounded-lg font-normal`}
                                                                >
                                                                    Bulk Edit
                                                                </button>
                                                            </div>
                                                        </div>
                                                        {showFilters && (
                                                            <div className="mt-2 px-2 pb-3 font-normal sm:px-2 lg:px-1">
                                                                <div className="grid grid-cols-1 gap-4 pr-4 sm:grid-cols-2 lg:grid-cols-3">
                                                                    <select
                                                                        name="tags"
                                                                        value={
                                                                            filters.tags ||
                                                                            ""
                                                                        }
                                                                        onChange={
                                                                            handleInputChange
                                                                        }
                                                                        className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none dark:border-gray-700 dark:bg-gray-800 dark:text-gray-300 dark:hover:bg-gray-700"
                                                                    >
                                                                        <option value="">
                                                                            All
                                                                            Tags
                                                                        </option>
                                                                        {tagsData?.ebayItemTags
                                                                            .slice()
                                                                            .sort(
                                                                                (
                                                                                    a: any,
                                                                                    b: any,
                                                                                ) =>
                                                                                    a.name.localeCompare(
                                                                                        b.name,
                                                                                    ),
                                                                            )
                                                                            .map(
                                                                                (
                                                                                    tag: any,
                                                                                ) => (
                                                                                    <option
                                                                                        key={
                                                                                            tag.id
                                                                                        }
                                                                                        value={
                                                                                            tag.id
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            tag.name
                                                                                        }
                                                                                    </option>
                                                                                ),
                                                                            )}
                                                                    </select>
                                                                    <select
                                                                        name="sourceLocationId"
                                                                        value={
                                                                            filters.sourceLocationId ||
                                                                            ""
                                                                        }
                                                                        onChange={
                                                                            handleInputChange
                                                                        }
                                                                        className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none dark:border-gray-700 dark:bg-gray-800 dark:text-gray-300 dark:hover:bg-gray-700"
                                                                    >
                                                                        <option value="">
                                                                            All
                                                                            Source
                                                                            Locations
                                                                        </option>
                                                                        {sourceLocationsData?.sourceLocations
                                                                            .slice()
                                                                            .sort(
                                                                                (
                                                                                    a: any,
                                                                                    b: any,
                                                                                ) =>
                                                                                    a.name.localeCompare(
                                                                                        b.name,
                                                                                    ),
                                                                            )
                                                                            .map(
                                                                                (
                                                                                    location: any,
                                                                                ) => (
                                                                                    <option
                                                                                        key={
                                                                                            location.id
                                                                                        }
                                                                                        value={
                                                                                            location.id
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            location.name
                                                                                        }
                                                                                    </option>
                                                                                ),
                                                                            )}
                                                                    </select>
                                                                    <select
                                                                        name="itemCategoryId"
                                                                        value={
                                                                            filters.itemCategoryId ||
                                                                            ""
                                                                        }
                                                                        onChange={
                                                                            handleInputChange
                                                                        }
                                                                        className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none dark:border-gray-700 dark:bg-gray-800 dark:text-gray-300 dark:hover:bg-gray-700"
                                                                    >
                                                                        <option value="">
                                                                            All
                                                                            Categories
                                                                        </option>
                                                                        {categoriesData?.itemCategories
                                                                            .slice()
                                                                            .sort(
                                                                                (
                                                                                    a: any,
                                                                                    b: any,
                                                                                ) =>
                                                                                    a.categoryName.localeCompare(
                                                                                        b.categoryName,
                                                                                    ),
                                                                            )
                                                                            .map(
                                                                                (
                                                                                    category: any,
                                                                                ) => (
                                                                                    <option
                                                                                        key={
                                                                                            category.id
                                                                                        }
                                                                                        value={
                                                                                            category.id
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            category.categoryName
                                                                                        }
                                                                                    </option>
                                                                                ),
                                                                            )}
                                                                    </select>
                                                                    <select
                                                                        name="itemBrandId"
                                                                        value={
                                                                            filters.itemBrandId ||
                                                                            ""
                                                                        }
                                                                        onChange={
                                                                            handleInputChange
                                                                        }
                                                                        className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none dark:border-gray-700 dark:bg-gray-800 dark:text-gray-300 dark:hover:bg-gray-700"
                                                                    >
                                                                        <option value="">
                                                                            All
                                                                            Brands
                                                                        </option>
                                                                        {brandsData?.brands
                                                                            .slice()
                                                                            .sort(
                                                                                (
                                                                                    a: any,
                                                                                    b: any,
                                                                                ) =>
                                                                                    a.name.localeCompare(
                                                                                        b.name,
                                                                                    ),
                                                                            )
                                                                            .map(
                                                                                (
                                                                                    brand: any,
                                                                                ) => (
                                                                                    <option
                                                                                        key={
                                                                                            brand.id
                                                                                        }
                                                                                        value={
                                                                                            brand.id
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            brand.name
                                                                                        }
                                                                                    </option>
                                                                                ),
                                                                            )}
                                                                    </select>
                                                                    <input
                                                                        type="number"
                                                                        name="listedPriceLte"
                                                                        placeholder="Max Listed Price"
                                                                        defaultValue={
                                                                            filters.listedPriceLte ||
                                                                            ""
                                                                        }
                                                                        onBlur={
                                                                            handleInputChange
                                                                        }
                                                                        className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none dark:border-gray-700 dark:bg-gray-800 dark:text-gray-300 dark:hover:bg-gray-700"
                                                                    />
                                                                    <input
                                                                        type="number"
                                                                        name="listedPriceGte"
                                                                        placeholder="Min Listed Price"
                                                                        defaultValue={
                                                                            filters.listedPriceGte ||
                                                                            ""
                                                                        }
                                                                        onBlur={
                                                                            handleInputChange
                                                                        }
                                                                        className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none dark:border-gray-700 dark:bg-gray-800 dark:text-gray-300 dark:hover:bg-gray-700"
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}
                                                    </th>
                                                </tr>
                                                <tr>
                                                    {/* Render Checkbox Column Header */}
                                                    <th
                                                        scope="col"
                                                        className="w-10 whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-bold dark:text-white sm:pl-0"
                                                    >
                                                        <div className="relative inline-block h-4 w-4">
                                                            <label
                                                                htmlFor="selectAllCheckbox"
                                                                className="absolute inset-0 flex h-full w-full cursor-pointer items-center justify-center rounded-full border-2 border-gray-400 dark:border-gray-600"
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    onChange={(
                                                                        e,
                                                                    ) => {
                                                                        const isChecked =
                                                                            e
                                                                                .target
                                                                                .checked;
                                                                        if (
                                                                            isChecked
                                                                        ) {
                                                                            // Select all items
                                                                            const allItemIds =
                                                                                products.map(
                                                                                    ({
                                                                                        node,
                                                                                    }: any) =>
                                                                                        decodeBase64Id(
                                                                                            node.id,
                                                                                        ),
                                                                                );
                                                                            setSelectedItems(
                                                                                allItemIds,
                                                                            );
                                                                        } else {
                                                                            // Deselect all items
                                                                            setSelectedItems(
                                                                                [],
                                                                            );
                                                                        }
                                                                    }}
                                                                    checked={
                                                                        products.length >
                                                                            0 &&
                                                                        selectedItems.length ===
                                                                            products.length
                                                                    }
                                                                    className="peer absolute h-4 w-4 opacity-0"
                                                                    id="selectAllCheckbox"
                                                                />

                                                                <svg
                                                                    className="opacity-0 peer-checked:opacity-100"
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <g
                                                                        id="SVGRepo_bgCarrier"
                                                                        strokeWidth="0"
                                                                    ></g>
                                                                    <g
                                                                        id="SVGRepo_tracerCarrier"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    ></g>
                                                                    <g id="SVGRepo_iconCarrier">
                                                                        <path
                                                                            fillRule="evenodd"
                                                                            clipRule="evenodd"
                                                                            d="M1.5 12C1.5 6.20101 6.20101 1.5 12 1.5C17.799 1.5 22.5 6.20101 22.5 12C22.5 17.799 17.799 22.5 12 22.5C6.20101 22.5 1.5 17.799 1.5 12ZM15.7127 10.7197C16.0055 10.4268 16.0055 9.95192 15.7127 9.65903C15.4198 9.36614 14.9449 9.36614 14.652 9.65903L10.9397 13.3713L9.34869 11.7804C9.0558 11.4875 8.58092 11.4875 8.28803 11.7804C7.99514 12.0732 7.99514 12.5481 8.28803 12.841L10.4093 14.9623C10.7022 15.2552 11.1771 15.2552 11.47 14.9623L15.7127 10.7197Z"
                                                                            fill="#0064d2"
                                                                        ></path>
                                                                    </g>
                                                                </svg>
                                                            </label>
                                                        </div>
                                                    </th>
                                                    {columns.map((col) => (
                                                        <th
                                                            key={col.key}
                                                            scope="col"
                                                            className="overflow-hidden text-ellipsis py-3.5 pl-4 pr-3 text-left text-sm font-bold dark:text-white sm:pl-0"
                                                        >
                                                            {col.label}
                                                        </th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            {/* Wrap tbody in scrollable container */}
                                            <tbody className="divide-y divide-gray-300 dark:divide-gray-800">
                                                {products.map(
                                                    ({ node }: any) => {
                                                        if (!node) return null; // Check if node exists
                                                        const itemId =
                                                            decodeBase64Id(
                                                                node.id,
                                                            );
                                                        return (
                                                            <tr key={node.id}>
                                                                {/* Render Checkbox for each row */}
                                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm dark:text-white sm:pl-0">
                                                                    <div className="relative inline-block h-4 w-4">
                                                                        <label
                                                                            htmlFor={`selectItemCheckbox-${itemId}`}
                                                                            className="absolute inset-0 flex h-full w-full cursor-pointer items-center justify-center rounded-full border-2 border-gray-400 dark:border-gray-600"
                                                                        >
                                                                            <input
                                                                                type="checkbox"
                                                                                checked={selectedItems.includes(
                                                                                    itemId,
                                                                                )}
                                                                                onChange={() =>
                                                                                    handleCheckboxChange(
                                                                                        itemId,
                                                                                    )
                                                                                }
                                                                                className="peer absolute h-5 w-5 opacity-0"
                                                                                id={`selectItemCheckbox-${itemId}`}
                                                                            />
                                                                            <svg
                                                                                className="opacity-0 peer-checked:opacity-100"
                                                                                viewBox="0 0 24 24"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <g
                                                                                    id="SVGRepo_bgCarrier"
                                                                                    strokeWidth="0"
                                                                                ></g>
                                                                                <g
                                                                                    id="SVGRepo_tracerCarrier"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                ></g>
                                                                                <g id="SVGRepo_iconCarrier">
                                                                                    <path
                                                                                        fillRule="evenodd"
                                                                                        clipRule="evenodd"
                                                                                        d="M1.5 12C1.5 6.20101 6.20101 1.5 12 1.5C17.799 1.5 22.5 6.20101 22.5 12C22.5 17.799 17.799 22.5 12 22.5C6.20101 22.5 1.5 17.799 1.5 12ZM15.7127 10.7197C16.0055 10.4268 16.0055 9.95192 15.7127 9.65903C15.4198 9.36614 14.9449 9.36614 14.652 9.65903L10.9397 13.3713L9.34869 11.7804C9.0558 11.4875 8.58092 11.4875 8.28803 11.7804C7.99514 12.0732 7.99514 12.5481 8.28803 12.841L10.4093 14.9623C10.7022 15.2552 11.1771 15.2552 11.47 14.9623L15.7127 10.7197Z"
                                                                                        fill="#0064d2"
                                                                                    ></path>
                                                                                </g>
                                                                            </svg>
                                                                        </label>
                                                                    </div>
                                                                </td>
                                                                {columns.map(
                                                                    (col) => (
                                                                        <td
                                                                            key={
                                                                                col.key
                                                                            }
                                                                            className="trunacte py-4 pl-4 pr-3 text-sm dark:text-white sm:pl-0 lg:max-w-64"
                                                                        >
                                                                            {col.render
                                                                                ? col.render(
                                                                                      node[
                                                                                          col
                                                                                              .key
                                                                                      ],
                                                                                      node,
                                                                                  )
                                                                                : (node[
                                                                                      col
                                                                                          .key
                                                                                  ] ??
                                                                                  "")}
                                                                        </td>
                                                                    ),
                                                                )}
                                                            </tr>
                                                        );
                                                    },
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <button
                                        onClick={handlePreviousPage}
                                        disabled={!pageInfo.hasPreviousPage}
                                        className="mr-2 hidden rounded bg-gray-300 px-4 py-2 text-gray-900"
                                    >
                                        Previous
                                    </button>
                                    <button
                                        onClick={handleNextPage}
                                        disabled={!pageInfo.hasNextPage}
                                        className="rounded bg-gray-300 px-4 py-2 text-gray-900"
                                    >
                                        Next
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <BulkEditEbayItemsModal
                    isOpen={isBulkEditModalOpen}
                    closeModal={closeBulkEditModal}
                    selectedProducts={selectedItems}
                />
            </div>
        </div>
    );
};

export default ActiveEbayItemsTableComponent;
