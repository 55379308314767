import SourceLocationsTableComponent from "@/components/dashboard/manage/source-locations/SourceLocationsTableComponent";

const sourceLocationsColumns = [
    {
        key: "name",
        label: "Source Location Name",
        render: (value: string) => value,
    },
    {
        key: "itemCount",
        label: "No. of Items",
        render: (value: number) => value,
    },
    {
        key: "archived",
        label: "Archived",
        render: (value: boolean) => (value ? "Yes" : "No"),
    },
];

export default function SourceLocationsPage() {
    return (
        <>
            <SourceLocationsTableComponent columns={sourceLocationsColumns} />
        </>
    );
}
